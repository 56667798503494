#quiz {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
}

#questionText {
  background-color: $imageButtonBackground;
  border-radius: 8px;
  color: $mainTextColor;
  font-size: 1.5rem;
  margin-bottom: 20px;
  padding: 20px;
  text-align: center;
  width: 100%;
}

.choices {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 20px;
  width: 100%;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 601px) and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.MuiButtonBase-root {
  align-items: center;
  background-color: $imageButtonBackground;
  display: flex;
  height: 200px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: background-color 0.3s;
  width: 100%;

  &:hover,
  &.Mui-focusVisible {
    z-index: 1;
    .MuiImageBackdrop-root {
      opacity: 0.15;
    }
    .MuiImageMarked-root {
      opacity: 0;
    }
    .MuiTypography-root {
      border: 4px solid currentColor;
    }
  }
}

.image-bg {
  background-position: center;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.image-overlay {
  align-items: center;
  background: $imageButtonBackground;
  box-sizing: border-box;
  color: $mainTextColor;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 10px;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1;
}

.image-text {
  background: white;
  border-radius: 4px;
  color: black;
  padding: 10px;
}

.quiz-navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;

  .navigation-button {
    align-items: center;
    background: $buttonLinkColor;
    border-radius: 10px;
    color: $mainButtonTextColor;
    font-weight: bold;
    margin: 0 10px;
    padding: 3vh;
    transition-duration: 0.4s;
    &:focus,
    &:hover {
      background-color: $hoverColor;
      color: $buttonLinkColor;
      outline: 2px solid $outlineColor;
      outline-offset: 2px;
    }
  }
}

.steps {
  padding: 10px;
  visibility: hidden;
}

@media screen and (min-width: 600px) {
  .steps {
    transform: scale(0.8);
    visibility: visible;
  }
}

@media screen and (min-width: 800px) {
  .steps {
    transform: scale(1);
    visibility: visible;
  }
}

@media screen and (min-width: 1400px) {
  .steps {
    transform: scale(1.2);
    visibility: visible;
  }
}
