header {
  .navbar {
    background: $backgroundPrimary;
  }

  .header-navigation-bar-button {
    padding: 10px;
    transition: transform 0.2s;
    vertical-align: middle;
    &:focus,
    &:hover {
      background-color: $hoverColor;
      color: $buttonLinkColor;
      outline: 2px solid $outlineColor;
      outline-offset: 2px;
      transform: scale(1.1);
    }
  }

  .dropdown-content {
    background-color: $backgroundPrimary;
    position: absolute;
    right: -11px;
    top: 110%;
  }

  .menu-item {
    a {
      display: inline-block;
      margin: 10px;
      padding: 20px;
      transition: transform 0.2s;
      vertical-align: middle;
      &:focus,
      &:hover {
        background-color: $hoverColor;
        color: $buttonLinkColor;
        outline: 2px solid $outlineColor;
        outline-offset: 2px;
        transform: scale(1.1);
      }
    }
  }

  .handleResetQuiz {
    #headerLogo {
      margin: 0;
      max-width: 100px;
      transition: transform 0.2s;
    }
    &:focus,
    &:hover {
      transform: scale(1.1);
    }
  }
}
