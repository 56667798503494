$blackOne: #000;
$blueOne: #31355a;
$blueTwo: #00008b;
$brownOne: #5a5731;
$grayOne: #2f4f4f;
$grayTwo: #808080;
$grayThree: #cfcbcb;
$greenOne: #315a42;
$purpleOne: #6f57c9;
$purpleTwo: #282339;
$purpleThree: #5a3149;
$whiteOne: #fff;

$backgroundPrimary: $purpleTwo;
$backgroundSecondary: $purpleOne;
$borderColor: $blackOne;
$buttonColor: $grayThree;
$buttonLinkColor: $whiteOne;
$hoverColor: $purpleThree;
$imageButtonBackground: $blackOne;
$mainButtonTextColor: $blackOne;
$mainTextColor: $whiteOne;
$outlineColor: $blackOne;
$youtubeBorder: $grayThree;
