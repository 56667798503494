* {
  box-sizing: border-box;
  font-family: "Lucida Console", "Courier New", monospace;
  scroll-behavior: smooth;
}

body {
  background: lightgoldenrodyellow;
  background-image: linear-gradient(
    180deg,
    $backgroundPrimary,
    $backgroundSecondary
  );
  display: flex;
  flex-direction: column;
  margin: 0vh;
  min-height: 100vh;
}

img {
  align-items: center;
  display: block;
  height: auto;
  margin: 2vh auto;
  max-width: 100%;
}

#quiz,
#questionText,
#choicesText,
#choices,
#quizComplete,
#errorScreen,
#completeTextContainer {
  align-items: center;
  padding: 1vh;
  text-align: center;
  width: 100%;
}

#errorContainer {
  padding-bottom: 3vh;
}
