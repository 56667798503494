.footer {
  color: $mainTextColor;
  margin: 0;
  padding: 10px 0;
  row-gap: 1rem;
  text-align: center;
  width: 100%;

  .companyName {
    font-weight: bold;
  }

  .social-links {
    display: flex;
    a {
      display: inline-block;
      justify-content: center;
      padding: 10px 10px;
      transition: transform 0.5s;

      &:focus,
      &:hover {
        background-color: $hoverColor;
        color: $buttonLinkColor;
        outline: 2px solid $outlineColor;
        outline-offset: 2px;
        transform: scale(0.8);
      }
    }
  }
}
