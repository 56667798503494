p,
h2,
a,
button {
  color: $mainTextColor;
}

#buttonHolder {
  display: grid;
  margin: 20px 0;
  place-items: center;
}

#resetQuizButton {
  align-items: center;
  background: $buttonColor;
  border-radius: 10px;
  color: $mainButtonTextColor;
  font-weight: bold;
  outline: 2px solid $outlineColor;
  padding: 3vh;
  transition-duration: 0.4s;

  &:focus,
  &:hover {
    background-color: $hoverColor;
    color: $buttonLinkColor;
    outline: 2px solid $outlineColor;
    outline-offset: 2px;
  }
}

.videoContent {
  padding: 5px;
}

.video-container {
  margin: auto;
  max-width: 560px;
  iframe {
    height: auto;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .video-container iframe {
    height: 315px;
    width: 560px;
  }
}

@media screen and (min-width: 1024px) {
  #results {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
  }
}
