.card {
  background-color: #000;
  margin: 15px;
}

.card-body {
  justify-content: center;
  padding-top: 0px;
}

figure {
  img {
    border-radius: 5%;
    margin: 25px;

    @media (max-width: 1024px) {
      width: 270px;
    }

    @media (max-width: 320px) {
      width: 250px;
    }
  }
}

h2 {
  justify-content: center;
}

.spotify-content {
  padding: 5px;
}
