#home {
  color: $mainTextColor;
  text-align: center;
  width: 100%;
}

.hero {
  min-height: 75vh;
}

#startQuizButton {
  align-items: center;
  background: $buttonColor;
  border-radius: 10px;
  color: $mainButtonTextColor;
  font-weight: bold;
  outline: 2px solid $outlineColor;
  padding: 3vh;
  transition-duration: 0.4s;

  &:focus,
  &:hover {
    background-color: $hoverColor;
    color: $buttonLinkColor;
    outline: 2px solid $outlineColor;
    outline-offset: 2px;
  }
}
